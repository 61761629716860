import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDrawer } from "@drawers/base-form";
import { ImageUtil } from "@services/image-util";
import { Utils } from "@services/utils";
import { WarpId } from '@wearewarp/universal-libs';

@Component({
  selector: '[upload-document-customer]',
  templateUrl: './upload-document.html',
  styleUrls: ['../../../../../app.scss', '../../../../../dialogs/dialogs.scss', '../../../../../drawers/drawer.scss']
})
export class UploadDocumentCustomer extends BaseFormDrawer {
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: {label: 'Customer', required: true},
    orderId: {label: 'Shipment ID', required: true},
    uploadDocument: {label: 'File for uploading', required: true, type: 'uploadFile'}
  };

  private _clients = [];
  @Input() set clients(value) {
    this._clients = value;
  }
  get clients() {
    return this._clients;
  }

  private _jobOrders = [];
  @Input() set jobOrders(value) {
    this._jobOrders = value;
  }
  get jobOrders() {
    return this._jobOrders;
  }

  public customerOrders = []
  public fileArrs = [];

  public get txtBtnOK() {return 'Upload'}
  public get iconBtnOK() {return 'upload'}

  public errMsg;
  public get labelSelectFile(): string {
    let key = 'uploadDocument';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : 'Select file'
  }

  public getShipmentId(id): string {
    if(!id) return ''
    return WarpId.showShipment(id)
  }

  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (Utils.isArrayNotEmpty(this.clients) && this.clients.length == 1) {
      this.setItemValue('clientId', this.clients[0].id);
      this.onClientChange(this.clients[0].id);
    }
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let json: any = super.getFormData_JSON(isCreateNew);
    return json;
  }

  protected showErr(err) {
    this.reset();
    this.errMsg = Utils.getErrorString(err);
  }

  protected showInfo(message: any): void {
    // do nothing
  }

  get needUpdate() {
    if (!this.hasAttachedFile('uploadDocument')) return false;
    return super.needUpdate;
  }

  protected startProgress() {
    super.startProgress();
    this.errMsg = '';
  }

  protected onCreateSuccess(resp) {
    this.createSuccess(resp);
    if (this.closeOnSuccess) {
      this.onClose();
    }
  }

  onClientChange(clientId) {
    this.customerOrders = this.jobOrders.filter(item => item.clientId == clientId);
    if (Utils.isArrayNotEmpty(this.customerOrders) && this.customerOrders.length == 1) {
      this.setItemValue('orderId', this.customerOrders[0]?.id);
    }
  }

  async onFileSelected(key, files: FileList) {
    if (files.length == 0) return;
    this.fileToUpload[key] = files[0];
    this.formInput.get(key)?.updateValueAndValidity();
    const maxLength = files.length;
    const arr = [];
    for (let index = 0; index < maxLength; index++) {
      const file = files[index];
      arr.push(ImageUtil.resizeImageIfNeeded(file));
    }
    const bolds = await Promise.all(arr);
    const fileArr = [];
    for (let index = 0; index < files.length; index++) {
      fileArr.push({ bold: bolds[index], name: files[index].name });
    }
    this.fileArrs = fileArr;
  }

  async onBtnUploadDocument() {
    const orderId = this.getItemValue('orderId');
    if (!orderId) return;
    this.startProgress();
    if (this.inputFile?.nativeElement?.value) {
      this.inputFile.nativeElement.value = "";
    }
    let apiUrl = `${Const.APIURI_ORDERS}/${orderId}/documents`;
    let formData = new FormData();
    for (let index = 0; index < this.fileArrs.length; index++) {
      const file = this.fileArrs[index];
      formData.append(`documentFiles.${index}`, file.bold, file.name);
    }
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        this.stopProgress();
        this.onCreateSuccess(resp);
        this.showSuccess("Your documents has been added successfully.");
      },
      (err) => {
        this.stopProgress();
        this.onClose();
        this.showErr(err);
      }
    );
  }

}
