<div class="form-header">
  <div class="form-title flex-space-between">
    <div class="f16 bold">Add Document Customer</div>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>
</div>
<div class="separator h top10"></div>
<div class="top20">
  <form class="form-detail" [formGroup]="formInput" nz-form>
  
    <div class="bottom20" *ngFor="let key of formInputKeys">
      <div class="form-register-value">
        <ng-container [ngSwitch]="key">
          <ng-container *ngSwitchCase="'clientId'">
            <div class="form-label-v2 bottom5" *ngIf="getLabel(key)">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-select nzBackdrop="true" style="width: 100%"
              nzAllowClear nzShowSearch
              [formControlName]="key"
              [nzDisabled]="onProgress"
              (ngModelChange)="onClientChange($event)">
              <nz-option *ngFor="let item of clients" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
            </nz-select>
          </ng-container>

          <ng-container *ngSwitchCase="'orderId'">
            <div class="form-label-v2 bottom5" *ngIf="getLabel(key)">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-select nzBackdrop="true" style="width: 100%"
              nzAllowClear nzShowSearch
              [nzDisabled]="onProgress"
              [formControlName]="key">
              <nz-option *ngFor="let item of customerOrders" [nzLabel]="getShipmentId(getWarpId(item))" [nzValue]="item.id"></nz-option>
            </nz-select>
          </ng-container>

          <ng-container *ngSwitchCase="'uploadDocument'">
            <input multiple #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(key, inputFile.files)" id="uploadDocumentInput">
            <button nz-button class="btn1" style="width: 100%;" (click)="inputFile.value=null;inputFile.click()" [disabled]="onProgress">
              <i *ngIf="hasAttachedFile(key)" nz-icon nzType="file-excel" nzTheme="outline"></i>
              {{labelSelectFile}}
              <span *ngIf="!hasAttachedFile(key)" class="label-mark-required"></span>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  
    <div *ngIf="errMsg" class="danger" style="white-space: pre-wrap; word-wrap: break-word; margin-top: 40px;">{{errMsg}}</div>
  </form>
  
  <div form-footer [onProgress]="onProgress"
    [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
    [nzIconOK]="iconBtnOK" [labelOK]="txtBtnOK" (onOK)="onBtnUploadDocument()"
    (onCancel)="onClose()"></div>
</div>

