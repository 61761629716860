import { Component, OnInit } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import RouteEntity from "../../entity/RouteEntity";
import { DispatchService } from "../../dispatchService";
import { getFeatureFlags } from "@services/feature-flag.service";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import _ from "underscore";
import dayjs from "dayjs";
import { Utils } from "@services/utils";

@Component({
  selector: 'dispatch-sidebar-map',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class DispatchSidebarMap extends BaseFormItem implements OnInit {  
  public isVisibleMap: boolean = false;
  public isLoading: boolean = true;
  protected route: RouteEntity;
  routeMapV2: boolean = true

  constructor(private dispatchService: DispatchService) {
    super();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        const tasks = this.tasks
        const open = tasks.filter(it => !it.status || it.status === 'created' || it.status === 'enroute')
        this.nextTask = open[0]
      })
    )
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
    // getFeatureFlags().isFlagSetNonBlocking('DISPATCH_ROUTEMAP_V1').subscribe((res) => {
    //   this.routeMapV2 = !res
    // })
  }

  get job() {
    if(!this.route) return {};
    return this.route.getData();
  }

  get shipments() {
    if(!this.route) return [];
    const shipments = this.route.getShipments();
    return shipments.map(item => item?.toJSON());
  }

  get tasks() {
    if(!this.route) return [];
    const tasks = this.route.getTasks();
    return tasks.map(item => item?.toJSON());
  }

  public get label() {
    if(!this.route) return '';
    const job = this.route.getData();
    return job ? job.label : ''
  }

  public get jobCode() {
    if(!this.route) return '';
    const job = this.route.getData();
    return job?.code ?? '';
  }

  get driverId() {
    return this.route?.getDriverId();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openMap() {
    this.isVisibleMap = true;
  }

  visibleChange() {
    this.isVisibleMap = false;
  }

  handleCancel() {
    this.isVisibleMap = false;
  }

  _nextTask: any = null
  get nextTask() {
      return this._nextTask
  }
  set nextTask(v) {
      this._nextTask = v
      this.lastLoaded = 0
      this.eta = null
      this.loadEta() // repeatedly ?
  }

  lastLoaded = 0
  interval = 60000
  eta = null
  loadEta() {
      if (!environment.eventUrl) return
      if (!this.nextTask) return
      if (this.lastLoaded > Date.now() - this.interval) return
      this.lastLoaded = Date.now()
      let url = `${environment.eventUrl}/delivery/TASK_${this.nextTask.id}/ETA?limit=1`;
      this.api.GET(url).subscribe(resp => {
          if (resp && resp.length > 0) {
              this.eta = this.processEta(resp[0])
          }
      },
      err => {
      })
  }

  processEta(update) {
    const { eta, ts } = update
    if (ts < Date.now() - 7200000) return null
    if (eta.distance) {
      const miles = Math.round(eta.distance / 1609.34).toLocaleString()
      update.miles = miles
    }
    if (eta.time) {
      update.hours = (eta.time /  3600).toFixed(1)
    }
    if (ts) {
      update.updatedAt = 'Updated at: ' + DateUtil.displayLocalTime(ts, {format: Const.FORMAT_GUI_DATETIME})
    }
    return update
  }

  downloading = false
  onDownloadGps() {
    const id = this.route?.getId()
    if (!id) return
    let url = `${environment.eventUrl}/delivery/JOB_${id}/LOCATION`;
    this.downloading = true
    this.api.GET(url).subscribe(resp => {
      if (resp && resp.length > 0) {
        const locs = _.sortBy(resp, "ts")
        const headers = ["Time", "latitude", "longitude"].join(",")
        const lines = locs.map(loc => {
          const ts = dayjs(loc.ts).toISOString()
          const line = [ts, loc.location.latitude.toFixed(4), loc.location.longitude.toFixed(4)]
          return line.join(",")
        })
        const csv = headers + "\n" + lines.join("\n")

        let blob = new Blob([csv], { type: 'text/csv' });
        let url = URL.createObjectURL(blob);
        let fileName = `${this.route.getCode()}_gps-${Date.now()}.csv`;
        Utils.downloadFile(url, fileName);
        this.downloading = false
      } else {
        this.showDialog(`Not GPS location data available for route ${this.route.getCode()}`)
        this.downloading = false
      }
    },
    err => {
      this.showErr(`Cannot download gps location for route ${this.route.getCode()}`)
    })
  }
}