<div class="customer-info">
    <div class="component-body">
        <div *ngFor="let customer of data ; let i = index" class="customer-item" style="padding: 4px 0;">
            <a [routerLink]="[this.routeAdminClientList, customer.id]" target="_blank"><span class="customer-name">{{customer.name}}: </span></a>
            <div nz-row [nzGutter]="{sm:16}" class="row-item" style="margin-left: 12x; padding-bottom: 10px ;">
                <div nz-col nzSm="0.5"></div>
                <div nz-col nzSm="32">
                    <div nz-row [nzGutter]="{sm:16}">
                        <span>Contact Name: {{getFullName(customer)}}</span>
                    </div>
                    <div nz-row [nzGutter]="{sm:16}">
                        <span>Phone Number: {{getPhone(customer)}}</span>
                    </div>
                    <div nz-row [nzGutter]="{sm:16}">
                        <span>Email: {{getEmail(customer)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>