import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { LatLng } from "@wearewarp/types/data-model";
import { EventSocket_DriverLocation } from "@wearewarp/types/event";
import { Utils } from "@services/utils";
import polyline from '@mapbox/polyline';
import mapboxgl from 'mapbox-gl';
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { Subscription, forkJoin } from 'rxjs';
import { Log } from "@services/log";
import { Mapbox } from "@services/mapbox";
import { SocketService } from "@services/socket.service";
import { MasterData } from "@services/master.data";

@Component({
  selector: "dispatch-map",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchMap extends BaseComponent {

  public mapBox: Mapbox;
  private markerDic: { [key: string]: any } = {};
  private _mapId: string;
  private sub: Subscription;
  public currentMapSources = [];
  public currentMapLayers = [];
  public currentMapImages = [];
  shipRecLocationHistories = [];
  private routeData;
  private etaNextStop;
  private currentLocation;
  private milesOut = undefined;

  @Input() job;
  @Input() listTasks;
  @Input() shipments;
  @Input() get mapId() { return this._mapId; };
  set mapId(value) {
    this._mapId = value;
    if (value) {
      this.shipRecLocationHistories = [];
      setTimeout(() => {
        this.getRouteData();
      }, 200);
    } else {
      this.stopRealTimeLocation();
      if (this.mapBox) {
        this.mapBox.remove();
        this.mapBox = null;
      }
    }
  }

  isLoading: boolean = true;
  @ViewChild('mapElm') mapElm: ElementRef<HTMLElement>;

  private socketService: SocketService;
  private driverLocationSubscription: Subscription;
  constructor(socketService: SocketService) {
    super();
    this.socketService = socketService;
  }

  public get idMap(): string {
    return `dispatch-map-${this.mapId}`
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.mapBox) {
      this.mapBox.remove();
      this.mapBox = null;
    }
  }

  getRouteData() {
    if (!this.job?.id) return
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_JOBS}/${this.job.id}/route-data`).subscribe(
      (resp) => {
        this.isLoading = false;
        this.routeData = resp?.data?.routeData || null;
        this.currentLocation = resp?.data?.lastKnownLocation || {}
        this.processSetupMap();
      },
      (err) => {
        this.isLoading = false;
        this.showErr(err);
        this.processSetupMap();
      }
    );
  }

  processSetupMap() {
    this.setupMap();
    this.addShipRecInfoToMap();
    this.startRealtimeLocation();
  }

  getEstimateTraffic() {
    if (!this.job?.id) return
    if (!this.currentLocation?.location?.latitude || !this.currentLocation?.location?.longitude) return
    let url = `${Const.APIURI_JOBS}/${this.job.id}/traffic-next-stops`;
    let data = {
      location: {
        lat: this.currentLocation.location.latitude,
        lng: this.currentLocation.location.longitude
      }
    }
    this.api.POST(url, data).subscribe(
      resp => {
        let eta = resp.data?.estimateTraffic?.[0]?.eta;
        let distance = resp.data?.estimateTraffic?.[0]?.distance;
        // time trả về đơn vị là giây(s), etaTime la isoString
        if (eta?.etaTime) {
          let timezone = DateUtil.Timezone_LA;
          if (eta?.metadata?.timeZoneStandard) timezone = eta.metadata.timeZoneStandard;
          let tzShore = DateUtil.timezoneStandardToUsShort(timezone);
          this.etaNextStop = `${DateUtil.displayLocalTime(eta.etaTime, {timezone, format: Const.FORMAT_GUI_DATETIME_V3})} (${tzShore})`;
        }
        if (Utils.isNumber(distance)) {
          this.milesOut = Const.metersToMiles(distance).toFixed(2);
        }
        this.updateDriverPopup({
          receivedTs: this.currentLocation.receivedTs,
          estSpeed: this.driverMarker?.speedHistories.slice(-1)?.[0]
        })
      }, err => {
      }
    );
  }

  private get isJobInProgress(): boolean {
    return this.job?.status == Const.JobStatus.inProgress;
  }

  private startRealtimeLocation() {
    if (!this.isJobInProgress) {
      return;
    }
    this.driverLocationSubscription = this.socketService.subscribeEvent<EventSocket_DriverLocation>("updateDriverLocation", data => {
      if (!data?.location || !data.jobIds?.length || !this.mapBox) return;
      if (!data.jobIds.includes(this.job.id)) return;

      this.updateDriverLocation({
        location: <any>data.location,
        receivedTs: new Date(data.when).getTime()
      })
      this.currentLocation = data;
    })
  }
  private stopRealTimeLocation() {
    this.driverLocationSubscription?.unsubscribe()
  }

  get map() {
    return this.mapBox.getMapbox(this.idMap);
  }

  private setupMap() {
    const colorPick = Const.MAP_LOCATION_COLOR_PICK;
    const colorDrop = Const.MAP_LOCATION_COLOR_DROP;
    let center = Const.MAP_LOCATION_LA;
    if (Utils.isArrayNotEmpty(this.listTasks) && this.listTasks[0].location) {
      center = [this.listTasks[0].location.longitude, this.listTasks[0].location.latitude];
    }
    this.mapBox = new Mapbox(center);
    this.markerDic = {};
    let coordinates = [];
    let features = [];
    let bounds;
    let points = this.routeData?.line ? polyline.decode(this.routeData.line) : []
    let routeCoordinates = points.map(it => [it[1], it[0]]);
    for (let i = 0; i < this.listTasks.length; i++) {
      let item = this.listTasks[i];
      const { address, location } = item || {}
      if (!location) {
        continue;
      }
      const { street, city, state, zipcode } = address || {}
      let key = this.textLatLng(item.location);
      let shipmentLabel = item?.label;
      const shipment = this.shipments.find(sh => sh.id === item.shipmentId);
      let shipmentIds = this.showShipmentWarpId(shipment?.warpId);
      let html = `<div class="clickable task-popup-item" id="task-popup-${i}"><div>${`${street}, ${city}, ${state} ${zipcode}`}</div><div>${item.type} Shipment ${shipmentIds} / ${shipmentLabel}</div></div>`;
      if (this.markerDic[key]) {
        this.markerDic[key].count++;
        this.markerDic[key].html += `<div class="clickable task-popup-item" id="task-popup-${i}"><div>${item.type} Shipment ${shipmentIds} / ${shipmentLabel}</div></div>`;
        // this.markerDic[key].marker.getPopup().setHTML(`<div class="task-popup-text">${this.markerDic[key].count} tasks at the same location<br>${this.markerDic[key].html}</div>`);
      } else {
        let popupOptions = {
          closeButton: false,
          closeOnClick: true,
          closeOnMove: true,
        };
        let popup = new mapboxgl.Popup(popupOptions).setHTML(`<div class="task-popup-text">${html}</div>`);
        let fnClick = (event) => {
          let idNum = Number(event.target.id.replace(/^task-popup-/, ''));
          this.setExpandTask(idNum, true);
        };
        popup.on('open', () => {
          let popupElem = popup.getElement();
          popupElem.querySelectorAll('.task-popup-item').forEach(it => {
            it.addEventListener('click', fnClick);
          });
        });
        popup.on('close', () => {
          let popupElem = popup.getElement();
          if (!popupElem) return;
          popupElem.querySelectorAll('.task-popup-item').forEach(it => {
            it.removeEventListener('click', fnClick);
          });
        });
        // let markerOptions = {
        //   color: item.type == 'PICKUP' ? colorPick : colorDrop,
        //   draggable: false
        // };
        if (!bounds) {
          bounds = new mapboxgl.LngLatBounds([location.longitude, location.latitude], [location.longitude, location.latitude]);
        } else {
          bounds.extend([location.longitude, location.latitude]);
        }
        coordinates.push([location.longitude, location.latitude]);
        features.push({
          'type': 'Feature',
          'properties': { type: item.type, key },
          'geometry': {
            'type': 'Point',
            'coordinates': [location.longitude, location.latitude]
          }
        });
        this.markerDic[key] = { location, count: 1, html, popup };
        // this.markerDic[key].marker = new mapboxgl.Marker(markerOptions)
        //   .setLngLat([location.longitude, location.latitude])
        //   .setPopup(popup)
        //   .addTo(this.map);
      }
    }

    this.map.on('style.load', () => {
      this.addLayerSoureImageMap(features, colorPick, colorDrop, routeCoordinates);

    });
    if (bounds) {
      if(this.mapId == 'minimum'){
        this.map.fitBounds(bounds, {padding: {top: 30, bottom: 30, right: 30, left: 30}, maxZoom: 15});
      }
      else{
        this.map.fitBounds(bounds, { padding: { top: 100, bottom: 100, right: 100, left: 300 }, maxZoom: 15 });
      }
    }
    this.map.on('click', 'points', (e) => {
      e.originalEvent.stopPropagation();
      let feature = e.features[0];
      let key = feature.properties.key;
      let location = this.markerDic[key].location;
      this.showMapPopup(location);
    });
    this.map.on('mousemove', 'points', (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
    })
    this.map.on('mouseleave', 'points', () => {
      this.map.getCanvas().style.cursor = '';
      // popup.remove();
    });
  }

  private loadImage(){
    let icons = [Const.MAP_LOCATION_ICON_PICK, Const.MAP_LOCATION_ICON_DROP]
    icons.forEach(icon => {
      this.map.loadImage(
        icon,
        (error, image) => {
        if (error) throw error;
        this.map.addImage(icon, image);      
      });
    });
    this.map.addLayer({
      'id': 'points',
      'type': 'symbol',
      'source': 'points',
      'layout': {
        'icon-image': [
          'match',
          ['get', 'type'],
          'PICKUP',
          Const.MAP_LOCATION_ICON_PICK,
          'DROPOFF',
          Const.MAP_LOCATION_ICON_DROP,
          'RETURN',
          Const.MAP_LOCATION_ICON_DROP,
          Const.MAP_LOCATION_ICON_DEFAULT,
        ],
        'icon-size': 0.5,
        'icon-anchor': 'bottom'
      }
    });
  }

  private addLayerSoureImageMap(features, colorPick, colorDrop, routeCoordinates) {
    this.map.addSource('points', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': features
      }
    });
    this.currentMapSources.push('points');
    // this.map.addLayer({
    //   'id': 'points',
    //   'type': 'circle',
    //   'source': 'points',
    //   'paint': {
    //     'circle-radius': 6,
    //     'circle-color': [
    //       'match',
    //       ['get', 'type'],
    //       'PICKUP',
    //       colorPick,
    //       'DROPOFF',
    //       colorDrop,
    //       'RETURN',
    //       colorDrop,
    //       Const.MAP_LOCATION_COLOR_DEFAULT,
    //     ],
    //     'circle-stroke-width': 3,
    //     'circle-stroke-color': 'rgba(45, 114, 210, 1)'
    //   },
    //   'filter': ['==', '$type', 'Point']
    // });
    this.loadImage()
    this.currentMapLayers.push('points');
    this.map.addSource('route', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': routeCoordinates
        }
      }
    });
    this.currentMapSources.push('route');
    this.map.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'route',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': Const.MAP_ROUTE_LINE_COLOR,
        'line-width': 3
      }
    });

    this.currentMapLayers.push('route');
    this.createDriverMarker(this.currentLocation);
  }

  prevLocation = {
    receivedTs: 0,
    location: { latitude: 0, longitude: 0 }
  };
  public updateDriverPopup({ receivedTs, estSpeed }: any) {
    let lastKnownTime = DateUtil.format(receivedTs, Const.FORMAT_GUI_DATETIME);
    this.driverPopup?.setHTML(`
    <p style="margin: 0"><b>Driver Info:</b></p>
    <p style="margin: 0"><b>Updated:</b> ${lastKnownTime || 'N/A'}</p>
    <p style="margin: 0"><b>Estimation Speed:</b> ${estSpeed !== undefined ? estSpeed + ' mph' : 'N/A'} </p>
    <p style="margin: 0"><b>ETA Next Stop:</b> ${this.etaNextStop ? this.etaNextStop : 'N/A'} <img id="icon-refresh-traffic" class="clickable"></p>
    <p style="margin: 0"><b>Miles out:</b> ${this.milesOut ? this.milesOut : 'N/A'} </p>
    `).setMaxWidth("350px")
    document.getElementById(`icon-refresh-traffic`)?.addEventListener("click", () => {
      this.getEstimateTraffic();
    });
  }
  public updateDriverLocation(lastKnownLocation: { location: LatLng, receivedTs: number, bearing?: number }) {
    if (!this.driverMarker) return;
    if(!lastKnownLocation?.location) return;

    this.driverMarker?.setLngLat([lastKnownLocation?.location?.longitude ?? 0, lastKnownLocation?.location?.latitude ?? 0])

    let estSpeed = this.driverMarker?.isActive ? this.mapBox?.getSpeed(
      this.prevLocation.location.latitude,
      this.prevLocation.location.longitude,
      lastKnownLocation.location.latitude,
      lastKnownLocation.location.longitude,
      this.prevLocation.receivedTs,
      lastKnownLocation.receivedTs
    ) : 0;

    //tính avg trung bình của 10 speed cuối
    //estSpeed > 200mph (~320km/h) => do GPS lag => không add vào histories
    if (Number(estSpeed) < 200) {
      this.driverMarker.speedHistories = [...(this.driverMarker?.speedHistories || []), Number(estSpeed)].slice(-10);
    }
    estSpeed = Math.round(this.driverMarker.speedHistories?.reduce((a, b) => a + b, 0) / this.driverMarker.speedHistories.length);
    if (!this.driverMarker?.isActive) estSpeed = 'N/A'

    this.updateDriverPopup({ receivedTs: lastKnownLocation?.receivedTs, estSpeed });
    let rotate = lastKnownLocation?.bearing ?? this.mapBox?.bearing(this.prevLocation.location, lastKnownLocation.location);
    this.driverMarker?.getElement().querySelector(".pulsating-circle")?.setAttribute("direction", rotate < 180 ? "1" : "0");
    this.driverMarker.getElement().querySelector(".pulsating-circle").style.transform = `rotate(${rotate}deg)`

    this.driverMarker.getElement().style.visibility = "visible"
    this.driverMarker.lastUpdate = lastKnownLocation?.receivedTs || 0;
    this.driverMarker.isActive = (Date.now() - this.driverMarker.lastUpdate < MasterData.const.intervalDriverOnline) ? 1 : 0;

    this.driverMarker?.getElement()?.querySelector(".pulsating-circle")?.setAttribute("active", this.driverMarker.isActive);
    this.driverMarker.activeTimeout = setTimeout(() => {
      clearTimeout(this.driverMarker?.activeTimeout);
      if (Date.now() - this.driverMarker.lastUpdate < MasterData.const.intervalDriverOnline * 0.9) return;
      this.driverMarker.isActive = 0;
      this.driverMarker?.getElement()?.querySelector(".pulsating-circle")?.setAttribute("active", "0");
    }, MasterData.const.intervalDriverOnline)
    this.prevLocation = lastKnownLocation;

  }

  private driverMarker;
  private driverPopup;

  private createDriverMarker(lastKnownLocation) {
    const el = document.createElement('div');
    el.innerHTML = "<div class='pulsating-circle'></div>";
    // el.style.backgroundSize = '100%';
    this.driverMarker = new mapboxgl.Marker(el).setLngLat([lastKnownLocation?.location?.longitude ?? 0, lastKnownLocation?.location?.latitude ?? 0]);
    this.driverMarker.addTo(this.map);
    this.driverMarker.speedHistories = [];
    if (lastKnownLocation?.location?.speed) {
      let speed = Number((lastKnownLocation?.location?.speed * 2.2369362921).toFixed(2)) //convert m/s to mph
      this.driverMarker.speedHistories.push(speed)
    }
    if (!lastKnownLocation?.location?.longitude) {
      this.driverMarker.getElement().style.visibility = "hidden"
    }
    window['x'] = this.driverMarker;

    //popup
    this.driverPopup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false
    });
    this.driverPopup.on('open', () => {
      this.getEstimateTraffic();
    })
    //this.driverMarker.isActive = 1; //set active để tính luôn speed và mile out khi bắt đầu bật map
    this.updateDriverLocation(lastKnownLocation);
    this.driverMarker.setPopup(this.driverPopup);

  }

  private showMapPopup(location: LatLng) {
    let key = this.textLatLng(location);
    // let popup = this.markerDic[key]?.marker?.getPopup();
    // if (popup && !popup.isOpen()) {
    //   this.markerDic[key].marker.togglePopup();
    // }
    let html;
    if (this.markerDic[key].count > 1) {
      html = `<div class="task-popup-text">${this.markerDic[key].count} tasks at the same location<br>${this.markerDic[key].html}</div>`;
    } else {
      html = `<div class="task-popup-text">${this.markerDic[key].html}</div>`;
    }
    this.markerDic[key].popup.setLngLat([location.longitude, location.latitude]).setHTML(html).addTo(this.map);
  }

  public setExpandTask(taskIndex: number, expand: boolean) {
    if (this.listTasks[taskIndex]) {
      this.listTasks[taskIndex].isExpand = expand;
      if (expand) {
        let el = document.getElementById(`task-item-${taskIndex}`);
        this.scrollToElement(el, { behavior: 'auto' });
      }
    }
  }

  public shouldExpandTask(index): boolean {
    return this.listTasks[index]?.isExpand == true;
  }

  public onTaskExpandChange(index: number, value) {
    if (this.listTasks[index]) {
      this.listTasks[index].isExpand = value;
    }
  }

  private addShipRecInfoToMap() {
    this.sub?.unsubscribe();
    let observables = [
    ];
    this.shipments.forEach((shipment) => {
      observables.push(this.api.GET(`p/tracking/shiprec?trackingCode=${shipment.trackingCode}`));
    });

    this.sub = forkJoin(observables).subscribe((results: Array<any>) => {
      Log.d('addShipRecInfoToMap results: ', results);
      this.shipments.forEach((shipment, index) => {
        const listData = results[index].data.list_data;
        listData.map(data => {
          data.shipmentId = shipment.id;
          return data;
        });
        this.shipRecLocationHistories = [...this.shipRecLocationHistories, ...listData];
      });
    }, err => {
      Log.e('addShipRecInfoToMap error: ', err);
    });
  }

}
